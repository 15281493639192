import React, { useState, useEffect, Component } from "react"
import {useTranslation} from 'gatsby-plugin-react-i18next';
import Insta1 from '../assets/images/insta1.png';
import Insta2 from '../assets/images/insta2.png';
import Insta3 from '../assets/images/insta3.png';
import Insta4 from '../assets/images/insta4.png';


const url = '/.netlify/functions/instagram';
function useInstagram() {
  const [posts, setPosts] = useState([])
  useEffect(() => {
    fetch(url)
      .then(res => res.json())
      .then(data => {
        setPosts(data)
      })
  }, [])
  return posts
}

export default function InstagramFeed() {
  const {t} = useTranslation();

  const gramz = useInstagram()
  return (
    <>
    <section id="wrap_insta">
      <div className="inner wrap_in">
      <section className="section instagram" id="instagram">
        <div className="container is-fluid">
        <div className="columns is-multiline is-mobile images is-variable is-gapless">
          <div className="column is-4-desktop is-hidden-mobile">
            <div className="inner">
            <i className="fa fa-instagram fa-5x" aria-hidden="true"></i>
            <p className="bold">{t('Zapratite nas i na Instagramu')}</p>
            <a href="https://www.instagram.com/hhauto1.1/" target="_blank" className="special">@hhauto1.1</a>
            </div>
          </div>
          <div className="column is-2-desktop is-3-mobile">
              <figure className="image is-square">
                <img src={Insta1} alt="Instagram image" />
              </figure>
          </div>
          <div className="column is-2-desktop is-3-mobile">
              <figure className="image is-square">
                <img src={Insta2} alt="Instagram image" />
              </figure>
          </div>
          <div className="column is-2-desktop is-3-mobile">
              <figure className="image is-square">
                <img src={Insta3} alt="Instagram image" />
              </figure>
          </div>
          <div className="column is-2-desktop is-3-mobile">
              <figure className="image is-square">
                <img src={Insta4} alt="Instagram image" />
              </figure>
          </div>
            
          </div>
        </div>
      </section>

      </div>
      </section>

      
    </>
  )
}