import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import {useTranslation} from 'gatsby-plugin-react-i18next';


const Menu = (props) => {
    const {t} = useTranslation();

    return(
    
    <nav id="menu">
        <div className="inner">
            <ul className="links">
            <li><Link onClick={props.onToggleMenu} to="/">{t('Početna')}</Link></li>
            <li><Link onClick={props.onToggleMenu} to="#about">{t('O nama')}</Link></li>
                <li><Link onClick={props.onToggleMenu} to="#cars">{t('Vozila')}</Link></li>
                <li><Link onClick={props.onToggleMenu} to="#rent">{t('Rent-a-car')}</Link></li>
                <li><Link onClick={props.onToggleMenu} to="#map">{t('Kontakt')}</Link></li>
            </ul>
           
        </div>
        <a className="close" onClick={props.onToggleMenu} href="javascript:;">Close</a>
    </nav>
)
    }

Menu.propTypes = {
    onToggleMenu: PropTypes.func
}

export default Menu
