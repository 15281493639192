import React, {useState} from 'react'
import {useTranslation} from 'gatsby-plugin-react-i18next';

const Contact = () => {
    const {t} = useTranslation();

    const [formState, setFormState] = useState({
        name:"",
        email:"",
        phone:"",
        message:"",
    })
    const encode = (data) => {
        return Object.keys(data)
            .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
            .join("&");
      }

    const handleInputChange = e =>{
        setFormState({
            ...formState,
            [e.target.name]:e.target.value,
        })
    }
    const handleSubmit = e =>{
        fetch("/", {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: encode({ "form-name": "contact", ...formState })
      })
        .then(() => alert("Success!"))
        .catch(error => alert(error));

      e.preventDefault();
    }


    return(
    <>
        <section className=" map" id="map">
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1429.571905051814!2d17.682910458085445!3d44.224697516875466!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x475eff78c24f32f3%3A0x8c9d5f12b271ff85!2sHH%20Auto!5e0!3m2!1sen!2sus!4v1612564140376!5m2!1sen!2sus" width="100%" height="450" frameBorder="0" allowFullScreen="" aria-hidden="false" tabIndex="0"></iframe>        </section>
        <section id="contact">
        <div className="inner">
            <section>
                <form name="contact" onSubmit={handleSubmit} method="post" data-netlify="true" data-netlify-honeypot="bot-field">
                <input type="hidden" name="form-name" value="contact" />

                    <div className="field half first">
                        <label htmlFor="name">{t('Ime')}</label>
                        <input type="text" name="name" id="name" value={formState.name}
            onChange={handleInputChange} />
                    </div>
                    <div className="field half ">
                        <label htmlFor="name">{t('Telefon')}</label>
                        <input type="text" name="phone" id="phone"  value={formState.phone}
            onChange={handleInputChange}/>
                    </div>
                    <div className="field ">
                        <label htmlFor="email">{t('Email')}</label>
                        <input type="text" name="email" id="email" value={formState.email}
            onChange={handleInputChange}/>
                    </div>
                    
                    <div className="field">
                        <label htmlFor="message">{t('Poruka')}</label>
                        <textarea name="message" id="message" rows="6" value={formState.message}
            onChange={handleInputChange}></textarea>
                    </div>
                    <ul className="actions">
                        <li><button type="submit" className="special">{t('Pošalji')}</button></li>
                        <li><button type="reset">{t('Poništi')}</button></li>
                    </ul>
                </form>
            </section>
            <section className="split">
                <section>
                    <div className="contact-method">
                        <span className="icon alt fa-envelope"></span>
                        <h3>{t('Email')}</h3>
                        <a href="mailto:hhauto.ba@icloud.com">hhauto.ba@icloud.com</a>
                    </div>
                </section>
                <section>
                    <div className="contact-method">
                        <span className="icon alt fa-phone"></span>
                        <h3>{t('Telefon')}</h3>
                        <a href="tel:0038761696704">(+387) 61 696 704</a>
                    </div>
                </section>
                <section>
                    <div className="contact-method">
                        <a href="https://goo.gl/maps/3cq9ASDr7FAFkF9a7" target="_blank">
                        <span className="icon alt fa-home"></span>
                        <h3>{t('Adresa')}</h3>
                        <span>Mostarska bb<br />
                        72270 Travnik,<br />
                        Bosnia i Hercegovina</span>
                        </a>
                       
                    </div>
                </section>
            </section>
        </div>
    </section>
    </>
)

}
 export default Contact;
