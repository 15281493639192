import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types'
import Logo from '../assets/images/logo_white.svg';
import {Link, useI18next} from 'gatsby-plugin-react-i18next';



const Header = (props) => {
    const {languages, originalPath} = useI18next();
    const [offset, setOffset] = useState(0);
    const [active, setActive] = useState("unactive");

    useEffect(() => {
      window.onscroll = () => {
        setOffset(window.pageYOffset);
             }
             if(offset>0){
               setActive("active")
             }
    }, []);
  
  
return (
    <header id="header" className={"alt no"+offset}>
        <Link to="/" className="logo">
            <figure className="image">
                <img src={Logo}/>
            </figure>
        </Link>
        <ul className="languages">
        {languages.map((lng) => (
          <li key={lng}>
            <Link to={originalPath} language={lng}>
              {lng}
            </Link>
          </li>
        ))}
      </ul>
        <nav>
            <a className="menu-link" onClick={props.onToggleMenu} href="javascript:;">Menu</a>
        </nav>

    </header>
)
}

Header.propTypes = {
    onToggleMenu: PropTypes.func
}

export default Header
